import { reqGetSystem } from '@/api';
export default {
    state: {
        system: null,
    },
    mutations: {
        setSystem(state, system) {
            state.system = system;
        },
    },
    actions: {
        async getSystem({ commit }) {
            try {
                const data = await reqGetSystem();
                commit('setSystem', data.data);
                return data.data;
            } catch (e) {
                return Promise.reject(e);
            }
        },
    },
    getters: {},
    namespaced: true,
};
