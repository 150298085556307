import { reqTypeList } from '@/api';

export default {
    // 用来存储数据
    state: {
        typeList: [],
    },
    // 操作数据
    mutations: {
        ['typeList'](state, { data }) {
            state.typeList = data;
        },
    },
    // 响应组件中的事件
    actions: {
        async getTypeList({ commit }) {
            const re = await reqTypeList();
            commit('typeList', { data: re.data });
        },
    },
    // 用来将state数据进行加工
    getters: {},
    namespaced: true,
};
